<!-- 绑定服刑人员 -->
<template>
  <div class="bind_relatives">
    <van-form class="form_body" @submit="onSubmit">
      <div class="form_content">
        <!-- 基本信息 -->
        <div class="label_title">填写基本信息</div>
        <van-field
          v-model="form.name"
          name="亲属姓名"
          label="亲属姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写亲属姓名' }]"
        />
        <van-field
          v-model="form.idCard"
          type="digit"
          name="身份证号"
          label="身份证号"
          placeholder="身份证"
          :rules="[{ validator: idCardRules, message: '请输入正确身份证号码' }]"
        />
        <!-- 手持照片 -->
        <div class="label_title photo_title_flex">
          <div>手持身份证正面</div>
          <div>手持身份证反面</div>
        </div>
        <div class="upload_box">
          <van-uploader :after-read="afterRead" />
          <van-uploader :after-read="afterRead" />
        </div>
        <!-- 联系方式 -->
        <div class="label_title">联系方式</div>
        <van-field
          v-model="form.phoneNum"
          name="手机号码"
          label="手机号码"
          placeholder="手机号"
          :rules="[{ required: true, message: '请输入手机号码' }]"
        />
      </div>
      <div class="form_bottom" v-show="hidshow">
        <van-button class="btn_bottom" round block type="info" native-type="submit">下一步</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import IdCardCheck from '@/utils/idCardCheck'
export default {
  name: 'BindRelatives',
  data () {
    return {
      docmHeight: document.documentElement.clientHeight, // 默认屏幕高度
      showHeight: document.documentElement.clientHeight, // 实时屏幕高度
      hidshow: true, // 显示或者隐藏footer
      form: {
        name: undefined,
        idCard: undefined,
        photo_positive: undefined,
        photo_back: undefined,
        phoneNum: undefined
      }
    }
  },
  mounted () {
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight
      })()
    }
  },
  watch: {
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.hidshow = false
      } else {
        this.hidshow = true
      }
    }
  },
  methods: {
    // 表单提交按钮
    onSubmit (val) {
      // if (!this.photo_positive) {
      //   this.$notify({ type: 'danger', message: '请上传手持身份证正面图片！' })
      //   return
      // }
      // if (!this.photo_back) {
      //   this.$notify({ type: 'danger', message: '请上传手持身份证反面图片！' })
      // }

      // 页面跳转至服刑人员绑定页面
      this.$router.push({ name: 'bindInmate', params: this.form })
    },
    // 身份证校验
    idCardRules (val) {
      return IdCardCheck(val)
    },
    afterRead (file) {

    }
  }
}
</script>
<style scoped>
.bind_relatives{
  width: 100vw;
  height: 100vh;
}
.form_body{
  width: 100%;
  height: 100%;
}
.form_content {
  width: 100%;
  height: 90%;
}
.label_title{
  margin: 0;
  padding: 16px 16px 16px;
  color: rgba(69, 90, 100, 0.6);
  background-color: #DCDFE6;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.photo_title_flex{
  display: flex;
  justify-content: space-around;
}
.upload_box{
  margin: 15px 0;
  display: flex;
  justify-content: space-around;
}
.upload_box ::v-deep .van-uploader__upload{
  width: 110px;
  height: 130px;
  margin: 0;
}
.form_bottom {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DCDFE6;
}
.btn_bottom{
  width: 90%;
}
</style>
