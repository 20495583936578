<!-- 亲属汇款 -->
<template>
  <div class="remittance">
    <div class="content_center">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad">
        <van-radio-group v-model="inmateObj" @change="checkHandle">
          <div class="item_info" v-for="(item,index) in list" :key="index">
            <van-radio :name="item"/>
            <div class="item_info_top">
              <div class="item_info_top_content">
                <div class="item_info_top_content_left">
                  <div class="top_left_name">
                    <span>{{item.inmateName}}</span>
                    <div class="write_box">
                      <van-tag type="primary" v-if="item.remittanceAccount ? !!item.remittanceAccount.includes('A') : false">白名单</van-tag>
                    </div>
                  </div>
                </div>
                <div class="item_info_top_content_right" v-if="item.remittanceAccount ? !!item.remittanceAccount.includes('A') : false">
                  <van-divider content-position="left" :style="{ color: '#1989fa', borderColor: '#1989fa'}">
                    账户余额
                  </van-divider>
                  <div>{{'基本账户：' + item.accountA + '元'}}</div>
                  <div>{{'激励账户：' + item.accountB + '元'}}</div>
                </div>
              </div>
            </div>
            <div class="item_info_bottom">
                <div>{{'资金账号：' + item.fundNumber}}</div>
            </div>
          </div>
        </van-radio-group>
      </van-list>
    </div>
    <template v-if="isCheckRadio">
      <!-- 不限制汇款时间 -->
      <!-- <van-notice-bar v-if="isLimitRemitTime" scrollable :text="'汇款时间为每天（早上：' + limitStartTime + ' - 下午：' +  limitEndTIme + '），请在指定时间段内进行汇款！'"/> -->
      <div class="check_account">
        <div class="account_content">
          <div class="account_label">选择汇款账户</div>
<!--          <span class="account_tips"><van-icon name="question-o"/>什么是汇款账户</span> 暂时不用-->
          <div class="account_radio">
            <van-radio-group v-model="account" direction="horizontal">
              <van-radio name="A" icon-size="16px" v-if="inmateObj.remittanceAccount ? inmateObj.remittanceAccount.includes('A') : false">基本账户<span style="font-size: 14px; color: #F56C6C;"> (可用于狱内消费)</span></van-radio>
              <van-radio name="D" icon-size="16px" v-if="inmateObj.remittanceAccount ? inmateObj.remittanceAccount.includes('D') : false">社会责任金账户<span style="font-size: 14px; color: #F56C6C;"> (不可用于狱内消费)</span></van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div class="bottom_money">
        <div class="top_up_limits">{{'可汇金额：' +  ( account !== 'D' ? inmateLimitInfo.remittableMoney  + ' 元' : '无限额（不可用于狱内消费）')}}</div>
        <van-field v-model="money" type="digit" label="汇款金额" name="汇款金额" :placeholder="'单笔限额：' + ( account !== 'D' ? inmateLimitInfo.highestLimit + ' 元' : '无金额上限' )" >
          <template #extra>
            <van-tag type="primary">{{'可汇次数：' + inmateLimitInfo.number}}</van-tag>
          </template>
        </van-field>
        <div style="font-size: 14px;font-weight: bold;margin-top: 5px;margin-left: 15px;">{{'金额大写：' + validMoney(money)}}</div>
        <div style="color: red;font-size: 14px;font-weight: bold;text-align: center;margin-top: 5px">提示：支付款项预计3-5个工作日到达对方账户</div>
        <div class="agree_box">
          <van-checkbox v-model="isAgree" class="isAgree_check" icon-size="16px"></van-checkbox>
<!--          <div class="agreeText_box">我已阅读并同意<span @click="agreeText">《在线汇款服务协议》</span></div> 还未确定好汇款协议-->
          <div class="agreeText_box">
            <div>本人同意并自愿将此款项支付至四川省{{prisonName}}</div>
            <span>{{'[ ' + checkInmate.inmateName + ' ] '}}</span>
            <span>{{account ? account === 'A' ? ' [ 基本账户 ] ' : account === 'D' ? ' [ 社会责任金账户 ] ' : '' : ''}}</span>
          </div>
        </div>
      </div>
      <div class="bottom_btn">
        <!-- 不限制汇款时间 -->
        <!-- <van-button class="btn_submit" round block type="info" :loading="btnLoading" loading-text="正在汇款中....." :disabled="account !== 'D' ? (!isAbelLimitBtn || inmateLimitInfo.remittableMoney <= 0 || money > inmateLimitInfo.highestLimit) : false" native-type="submit" @click="submitRemittance">立即汇款</van-button> -->
        <van-button class="btn_submit" round block type="info" :loading="btnLoading" loading-text="正在汇款中....." :disabled="account !== 'D' ? (inmateLimitInfo.remittableMoney <= 0 || money > inmateLimitInfo.highestLimit) : false" native-type="submit" @click="submitRemittance">立即汇款</van-button>
      </div>
    </template>
  </div>
</template>

<script>
import { getAccountBalance, getInmateLimitInfo, getInmatePersonDataBinding, startRecharge } from '@/api'
import { toChinesNum } from '@/utils/toChinesNum'

export default {
  data () {
    return {
      checkInmate: undefined,
      docmHeight: document.documentElement.clientHeight, // 默认屏幕高度
      showHeight: document.documentElement.clientHeight, // 实时屏幕高度
      hidshow: true, // 显示或者隐藏footer
      // 选中的人员
      inmateObj: {},
      isCheckRadio: false,
      // 当前登录人的ID
      currentUserID: undefined,
      list: [], // { inmateName: '**埠', inmateId: '5', inmateCardNo: '12039412311222222222', prisonName: '眉州监狱', areaName: '一监区', appName: '非白名单', appStatus: '1', isValid: '1', validName: '无效' }
      queryParams: {
        state: 0,
        page: 0,
        size: 5
      },
      inmateLimitInfo: {
        remittableMoney: 0,
        highestLimit: 0,
        number: 0
      },
      loading: false,
      finished: false,
      error: false,
      account: undefined,
      isAgree: false,
      money: undefined,
      // 防暴力点击事件
      btnLoading: false,
      // 汇款时间限制相关参数
      isLimitRemitTime: this.$application.isLimitRemitDate ? this.$application.isLimitRemitDate : false,
      // 不限制汇款时间
      // limitStartTime: this.$application.limitDate ? this.$application.limitDate.start : undefined,
      limitEndTIme: this.$application.limitDate ? this.$application.limitDate.end : undefined,
      // 当前系统时间
      // isAbelLimitBtn: false,      // 不限制汇款时间
      // 定时器
      timer: undefined,
      prisonName: ''
    }
  },
  created () {
    this.currentUserID = this.$store.state.user.id

    this.prisonName = localStorage.getItem('prisonName') ? localStorage.getItem('prisonName') : '错误监狱'
  },
  mounted () {
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight
      })()
    }
    if (this.isLimitRemitTime) {
      // 页面加载完后显示当前时间
      this.dealWithTime(new Date())
      // 定时刷新时间
      this.timer = setInterval(() => {
        this.dealWithTime(new Date()) // 修改数据date
      }, 1000)
    }
  },
  watch: {
    showHeight: function () {
      this.hidshow = this.docmHeight <= this.showHeight
    }
  },
  methods: {
    onLoad () {
      // 异步更新数据
      this.queryParams.page = this.list.length / this.queryParams.size
      getInmatePersonDataBinding(this.queryParams).then(res => {
        if (res.code === 200) {
          this.list = this.list.concat(res.data.content)
          this.loading = false
          // 数据全部加载完成
          if (this.list.length >= res.data.totalElements) {
            this.finished = true
          }
        } else {
          this.error = false
        }
      }).then(a => {
        // 服刑人员列表
        const ids = []
        // 服刑人员所属监区列表
        const areaIds = []
        this.list.forEach(item => {
          ids.push(item.fundNumber)
          areaIds.push(item.code)
        })
        getAccountBalance({ fundNumbers: ids, codes: areaIds }).then(res => {
          if (res.code === 200) {
            this.list.map((item, index1) => {
              res.data.content.find((_item, index) => {
                if (item.fundNumber === _item.fundNumber) {
                  // 余额信息绑定
                  this.$set(this.list[index1], 'accountA', res.data.content[index].accountA)
                  this.$set(this.list[index1], 'accountB', res.data.content[index].accountB)
                  this.$set(this.list[index1], 'accountC', res.data.content[index].accountC)
                  this.$set(this.list[index1], 'accountD', res.data.content[index].accountD)
                }
              })
            })
          }
        })
      })
    },
    checkHandle (item) {
      // 是否已选择人员
      this.isCheckRadio = true
      // 选中人员缓存
      this.checkInmate = item
      // 查询已选择服刑人员充值限制
      getInmateLimitInfo({ code: item.code, fundNumber: item.fundNumber }).then(res => {
        if (res.code === 200) {
          this.inmateLimitInfo = res.data
          // 汇款限制
          this.inmateLimitInfo.number = item.number
        }
      })
    },
    agreeText () {

    },
    submitRemittance () {
      // 判断是否选择服刑人员
      if (!(this.inmateObj && JSON.stringify(this.inmateObj) !== '{}')) {
        this.$notify({
          type: 'warning',
          message: '请选择汇款人员！'
        })
        return
      }
      // 判断是否选择汇款账户
      if (!this.account) {
        this.$notify({
          type: 'warning',
          message: '请选择汇款账户！'
        })
        return
      }
      // 判断是否输入汇款金额
      if (!this.money) {
        this.$notify({
          type: 'warning',
          message: '请输入汇款金额！'
        })
        return
      }
      // 先阅读在线汇款服务
      if (!this.isAgree) {
        this.$notify({
          type: 'warning',
          message: '请先阅读在线汇款服务！'
        })
        return
      }
      // 封装请求参数
      const params = {}
      params.orderAmount = this.money // 汇款金额
      params.code = this.inmateObj.code // 汇款人所属监区
      params.peopleBindingId = this.inmateObj.id // 绑定的服刑人员ID标识
      params.remittanceAccount = this.account // 汇款账户
      params.relativesId = this.currentUserID
      // 开启按钮拦截
      this.btnLoading = true
      // 调用汇款接口拉起支付界面
      startRecharge(params).then(res => {
        if (res.code === 200) {
          if (res.data.oneQrforAll) {
            // 打开支付连接，弹出微信支付界面
            window.location.href = res.data.oneQrforAll
          }
        } else {
          // 打印错误信息
          this.$toast({
            type: 'fail',
            duration: 0,
            overlay: true,
            closeOnClickOverlay: true,
            message: res.message,
            className: 'custom-van-toast-re-width'
          })
        }
        // 拉取成功，关闭按钮拦截
        this.btnLoading = false
      })
    },
    // 不限制汇款时间
    // dealWithTime (data) { // 获取当前时间
    //   let H = data.getHours()
    //   let Min = data.getMinutes()
    //   let S = data.getSeconds()
    //   H = H < 10 ? '0' + H : H
    //   Min = Min < 10 ? '0' + Min : Min
    //   S = S < 10 ? '0' + S : S
    //   const currentTime = H + ':' + Min + ':' + S
    //   // 格式化时间
    //   const currentTimeNum = currentTime.replace(/:/g, '')
    //   const startTimeNum = this.limitStartTime.replace(/:/g, '')
    //   const endTimeNum = this.limitEndTIme.replace(/:/g, '')
    //   // 转化成时间戳作比较
    //   this.isAbelLimitBtn = startTimeNum < currentTimeNum && currentTimeNum < endTimeNum
    // },
    validMoney (money) {
      return toChinesNum(money)
    }
  },
  destroyed () {
    if (this.timer) { // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.timer)
    }
  }
}

</script>
<style scoped>
.remittance{
  width: 100vw;
  height: 100vh;
}
.content_center{
  width: 100%;
  height: 360px;
  overflow-y: auto;
}
.content_center::-webkit-scrollbar{
  display: none;
}
.item_info{
    width: 90%;
    height: 110px;
    margin: 15px auto;
    border-radius: 10px;
    background-color: #ecf5ff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.content_center ::v-deep .van-radio{
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #fff;
  border-radius: 10px;
}
.item_info_top{
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_info_top_content{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.item_info_top_content_left{
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.top_left_label{
  font-size: 13px;
  color: #909399;
  height: 60px;
  width: 40px;
  line-height: 40px;
}
.top_left_name{
  font-size: 28px;
  color: #0f365f;
  font-weight: bold;
  width: 150px;
  height: 80px;
  line-height: 80px;
  display: flex;
  justify-content: space-between;
}
.top_left_name span{
  top: -20px;
  right: -5px;
}
.write_box {
  width: 70px;
}
.item_info_top_content_right{
  width: 40%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  font-size: 12px;
}
.item_info_top_content_right  ::v-deep .van-divider {
  margin: 0 5px;
  font-size: 12px;
  line-height: 12px;
}
.item_info_bottom{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_info_bottom div{
  font-size: 16px;
  color: #0f365f;
  width: 290px;
}
.circl_box{
  text-align: center;
  width: 100px;
  height: 50px;
  padding-top: 5px;
  background-color: #d9ecff;
  border-radius: 50%;
  position: relative;
}
.circl_box div{
  width: 100%;
  line-height: 50px;
  font-size: 16px;
}
.tag_text{
  width: 30px;
  position: absolute;
  top: 15px;
  right: -30px;
}
.fs{
  color: #07c160;
}
.check_account{
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F2F3F5;
}
.account_content{
  width: 100%;
  height: 80%;
  background-color: #fff;
}
.account_label{
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  padding-left: 20px;
  display: inline;
}
.account_tips{
  font-size: 12px;
  float: right;
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
  color: #FAAB0C;
}
.account_radio{
  height: 35px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}
.bottom_money{
  width: 100%;
  height: 180px;
}
.bottom_money ::v-deep .van-field__label{
  color: #303133;
  font-weight: bold;
}
.top_up_limits{
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
  height: 20px;
  line-height: 20px;
  color: #67C23A;
}
.agree_box{
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.isAgree_check{
  margin-left: 15px;
}
.agreeText_box {
  display: inline;
  font-size: 14px;
  margin-left: 10px;
}
.agreeText_box span {
  color: #409EFF;
}
.bottom_btn{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
.btn_submit{
  width: 80%;
  height: 100%;
}
</style>
<style>
.custom-van-toast-re-width {
  width: 180px;
}
</style>
