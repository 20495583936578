<!-- 绑定服刑人员信息 -->
<template>
  <div class="bind_inmate">
    <van-form class="form_body" @submit="onSubmit">
      <div class="form_content">
        <!-- 服刑人员资金账号 -->
        <div class="label_title">绑定服刑人员信息</div>
        <van-field
          v-model="form.capitalCard"
          name="人员资金账号"
          label="人员资金账号"
          placeholder="账号"
          :rules="[{ required: true, message: '请填写资金账号' }]"
        />
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: 'bindInmate',
  data () {
    return {
      form: {
        capitalCard: undefined
      }
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const pageParam = this.$route.params
      console.log(pageParam)
    }
  }
}

</script>
<style lang='scss' scoped>
</style>
