<!--  -->
<template>
  <div class="remit_record">
    <div class="content_list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad">
        <div v-for="(item,index) in list" :key="index">
          <van-swipe-cell>
            <div class="record_card" >
              <div class="content_left">
                <div class="inmate_name"><van-icon name="gold-coin" class="icon_margin"/>{{'充值给：' + item.inmateName}}{{!!item.remittanceAccount ? item.remittanceAccount === 'A' ? '[基本账户]' : item.remittanceAccount === 'D' ? '[社会责任金]' : item.remittanceAccount : ''}}</div>
                <div style="display: flex">
                  <div style="width: 50px; height: 40px; line-height: 40px;font-weight: bold;color: #000">金额：</div>
                  <div :class="item.state === 0 ? 'top_money fw' : 'top_money'" style="width: 80%;height: 40px;line-height: 40px">{{item.orderAmount}}</div>
                </div>
                <div class="create_time">{{'汇款时间：' + item.createTime}}</div>
                <div class="inmate_id">{{'资金账号：' + item.fundNumber}}</div>
                <div class="record_num">{{'汇款类型：' + item.orderNo.split('-')[0]}}</div>
                <div class="record_num">{{'汇款单号：' + item.orderNo.split('-')[2]}}</div>
              </div>
              <div class="content_right">
                <div class="bottom_status">
                  <van-button type="warning" style="width: 80px" round plain v-if="item.state === 0" size="mini" :loading="!!item.btnLoading" loading-text="汇款中.." @click="remitOrderForm(item)">待支付</van-button>
                  <van-tag type="success" v-if="item.state === 1" size="large">已付款<van-icon name="clock-o" /></van-tag>
                  <van-tag type="success" v-if="item.state === 3" size="large">已到账<van-icon name="passed" /></van-tag>
                  <van-tag type="primary" v-if="item.state === 2" size="large">退款中<van-icon name="clock-o" /></van-tag>
                  <van-tag type="primary" v-if="item.state === 5" size="large">已退款<van-icon name="passed" /></van-tag>
                  <van-tag type="danger" v-if="item.state === 4" size="large">失效订单</van-tag>
                  <van-tag type="danger" v-if="item.state === 999" size="large">错误订单</van-tag>
                </div>
              </div>
            </div>
<!--            <template v-if="item.state === 0" #right>-->
<!--              <van-button square type="primary" style="height: 100%" :loading="btnLoading" loading-text="汇款中.." text="汇款"  @click="remitOrderForm(item.code, item.orderNo)"/>-->
<!--            </template>-->
          </van-swipe-cell>
        </div>
      </van-list>
    </div>
    <van-tabbar v-model="active" @change="onLoad('tabbar')">
      <van-tabbar-item>
        <span>待付款</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>已付款</span>
        <template #icon="props">
          <img :src="props.active ? icon_1.active : icon_1.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { getOrderList, repayment } from '@/api'
import { Toast } from 'vant'

export default {
  data () {
    return {
      list: [
        // { inmateName: '*琪', inmateId: '3', inmateCardNo: '14256222222222222222', recordName: '202203201416', money: '500', createTime: '2022-03-30 14:17:20', statusLabel: '充值中', statusNum: '0' },
      ],
      queryParams: {
        showInmateName: true,
        state: 0,
        page: 0,
        size: 5
      },
      btnLoading: false,
      currentUserCardNo: undefined,
      loading: undefined,
      finished: false,
      error: false,
      active: 0,
      icon: {
        active: require('@/assets/images/16-1.svg'),
        inactive: require('@/assets/images/16.svg')
      },
      icon_1: {
        active: require('@/assets/images/15-1.svg'),
        inactive: require('@/assets/images/15.svg')
      }
    }
  },
  created () {
    this.currentUserCardNo = this.$store.state.user.cardNo
  },
  methods: {
    onLoad (type) {
      if (type === 'tabbar') {
        this.loading = true
        this.finished = false
        this.list = []
      }
      // 异步更新数据
      this.queryParams.page = this.list.length / this.queryParams.size
      // 状态码封装
      if (this.active === 0) {
        this.queryParams.state = -1
        this.queryParams.states = [0]
      } else if (this.active === 1) {
        this.queryParams.state = -1
        this.queryParams.states = [1, 2, 3, 4, 5, 999]
      }
      getOrderList(this.queryParams).then(res => {
        if (res.code === 200) {
          this.list = this.list.concat(res.data.content)
          this.loading = false

          // 数据全部加载完成
          if (this.list.length >= res.data.totalElements) {
            this.finished = true
          }
        } else {
          this.error = false
        }
      })
    },
    remitOrderForm (item) {
      const params = {}
      params.code = item.code // 监狱编号
      params.orderNo = item.orderNo // 订单号
      this.$set(item, 'btnLoading', true)
      repayment(params).then(res => {
        this.$set(item, 'btnLoading', false)
        if (res.code === 200) {
          if (!res.data.oneQrforAll) {
            this.onLoad('tabbar')
          } else {
            window.location.href = res.data.oneQrforAll
          }
        } else {
          Toast({ type: 'fail', duration: 3000, message: res.message })
          this.onLoad('tabbar')
        }
      })
    }
  }
}

</script>
<style scoped>
.remit_record{
  width: 100vw;
  height: 100vh;
  background-color: #f7f8fa;
}
.content_list{
  width: 100%;
  height: 93%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}
.content_list::-webkit-scrollbar{
  display: none;
}
.record_card{
  width: 90%;
  height: 150px;
  padding-left: 10px;
  margin: 10px auto;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
.content_left{
  width: 75%;
  height: 100%;
  display: flex;
  font-size: 14px;
  color: #909399;
  flex-direction: column;
  justify-content: space-around;
}
.inmate_name{
  color: #0f365f;
  font-size: 18px;
}
.icon_margin{
  margin-right: 5px;
}
.content_right{
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top_money{
  font-weight: bolder;
  font-size: 32px;
  color: #38f;
}
.fw {
  color: #E6A23C;
}
</style>
