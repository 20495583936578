<template>
  <div class="login_body" :style="{height:docmHeight+'px', overflow: 'hidden'}">
    <div style="width: 100vw; height: 23%"></div>

    <div style="width: 100vw; height: 60%"><div class="login_box">
      <div class="help_word">
        <span class="help_word_text" @click="helpWordBtn"><van-icon style="margin-right: 5px" name="question-o"/>用户操作指南</span>
      </div>
      <div class="header_title">欢迎登录</div>
      <van-form @submit="onSubmit" style="height: 180px" :show-error-message="false">
        <van-field
          v-model="username"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          v-model="sms"
          center
          label="短信验证码"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button size="small" type="primary" :disabled="!isSend" @click="useVerify" native-type="button" :loading="sendLoading" loading-text="发送中">
              {{!isSend ? "重新发送(" + count + ")" : "发送验证码"}}
            </van-button>
          </template>
        </van-field>
        <div style="width: 70%;margin: 20px auto;">
          <van-button type="info" plain round block :loading="btnLoading" loading-text="登录中....." native-type="submit">登录</van-button>
        </div>
      </van-form>
      <div class="agree_box">
        <van-checkbox v-model="isAgree" class="isAgree_check" icon-size="16px"></van-checkbox>
        <div class="agreeText_box">我已阅读并同意<span @click="agreeText" native-type="button">{{this.text1Name}}</span></div>
      </div>
    </div></div>

    <!--  注意事项  -->
<!--    <div class="bottom_warning">-->
<!--      <div style="font-size: 16px; width: 90%;color: #303133; margin: 0 auto 10px;font-weight: bold">-->
<!--        <span style="color: red">*</span>-->
<!--        <span style="color: #303133">提示：经监狱审核通过的亲属手机号可直接登录本系统，无需注册。如果登录时提示“手机号不存在或已停用”，请与对应监狱联系！</span>-->
<!--      </div>-->
<!--    </div>-->

    <Verify
      @success="success"
      mode="pop"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    />
    <!--  告知书弹框  -->
    <van-popup v-model="show1" :close-on-click-overlay="false">
      <div class="document_work" ref="file1"></div>
      <div style="padding: 5px"><van-button type="info" plain block round @click="agreeBtn">我已阅读并同意</van-button></div>
    </van-popup>
    <!--  操作指南弹框  -->
    <van-popup v-model="show2" :close-on-click-overlay="false">
      <div class="document_work" ref="file2"></div>
      <div style="padding: 5px"><van-button type="info" plain block round @click="show2 = false">关闭</van-button></div>
    </van-popup>
    <!--  登录账号错误提示弹框  -->
    <van-popup v-model="show3" :close-on-click-overlay="false">
      <div class="document_work" ref="file3"></div>
      <div style="padding: 5px"><van-button type="info" plain block round @click="show3 = false">本 人 已 阅 读</van-button></div>
    </van-popup>
  </div>
</template>

<script>
// 引入行为验证码组件
import Verify from '@/components/Verifition/Verify'
import { sendPhoneCode } from '@/api/login'
import { fileReader } from '@/api/file'
import { Toast } from 'vant'
// 预览插件
const docx = require('docx-preview')
export default {
  name: 'Login',
  components: { Verify },
  data () {
    return {
      username: undefined,
      password: undefined,
      docmHeight: document.documentElement.clientHeight,
      btnLoading: false,
      isAgree: false,
      sendLoading: false,
      sms: undefined,
      isSend: true,
      count: 60,
      timer: undefined,
      show1: false,
      show2: false,
      show3: true,
      text1Url: '',
      text1Name: '',
      text3Url: ''
    }
  },
  created () {
    // 获取对应文章的访问路径
    this.getPrisonArticleUrl()
    // 本地拿到时间
    const time = localStorage.getItem('time')
    // 判断是否大于0
    if (time && time > 0) {
      //  解决刷新页面按钮有空白情况
      this.count = time
      this.countDown()
    }
    // 初始化加载弹框
    this.openUserNameErrorPopUp()
  },
  methods: {
    getPrisonArticleUrl () {
      const text1Obj = this.$application.notifyWordUrl
      const text3Obj = this.$application.userErrorUrl
      const prisonCode = localStorage.getItem('prisonCode')
      if (!prisonCode) {
        Toast({
          type: 'fail',
          duration: 1500, // 持续展示 toast
          forbidClick: true,
          message: '获取单位信息失败,请退出公众号重试！'
        })
        return
      }
      const text1ChildObj = text1Obj[prisonCode]
      if (text1ChildObj) {
        this.text1Name = text1ChildObj.name
        this.text1Url = text1ChildObj.url
      }
      this.text3Url = text3Obj[prisonCode]
    },
    openUserNameErrorPopUp () {
      this.show3 = true
      fileReader(this.text3Url).then(res => {
        docx.renderAsync(res, this.$refs.file3)
      })
    },
    helpWordBtn () {
      this.show2 = true
      fileReader(this.$application.userHandleWordUrl).then(res => {
        docx.renderAsync(res, this.$refs.file2)
      })
    },
    agreeBtn () {
      this.isAgree = true
      this.show1 = false
    },
    agreeText () {
      // 获取对应单位的文章路径
      this.show1 = true
      fileReader(this.text1Url).then(res => {
        docx.renderAsync(res, this.$refs.file1)
      })
    },
    onSubmit () {
      // 先阅读在线汇款服务
      if (!this.isAgree) {
        Toast({ type: 'fail', duration: 3000, message: '请先阅读智慧生活平台告知书！', className: 'custom-van-toast-width' })
        return
      }
      this.btnLoading = true
      this.$store.dispatch('Login', { username: this.username, password: null, phoneCode: this.sms, code: localStorage.getItem('prisonCode') }).then((res) => {
        if (res.code === 200) {
          // 初始化倒计时
          clearInterval(this.timer)
          localStorage.setItem('time', 0)
          // 跳转首页
          this.$router.replace('/home')
        } else {
          Toast({ type: 'fail', duration: 3000, message: res.message, className: 'custom-van-toast-width' })
        }
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
      })
    },
    success (params) {
      this.sendLoading = true
      // 发送验证码
      sendPhoneCode({ username: this.username, code: localStorage.getItem('prisonCode') }).then(res => {
        if (res.code === 200) {
          // 开启倒计时
          this.countDown()
          Toast({ type: 'success', duration: 3000, message: '验证码发送成功！' })
        } else if (res.code === 500) {
          Toast({ type: 'fail', duration: 3000, message: res.message })
        } else {
          Toast({ type: 'fail', duration: 3000, message: '验证码发送失败！' })
        }
        this.sendLoading = false
      }).catch(() => {
        this.sendLoading = false
      })
    },
    countDown () {
      // 关闭发送
      this.isSend = false
      // 开启倒计时
      localStorage.setItem('time', this.count)
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--
          localStorage.setItem('time', this.count)
        } else {
          clearInterval(this.timer)
          this.count = 60
          this.isSend = true
        }
      }, 1000)
    },
    useVerify () {
      // 校验手机号是否填写
      if (!this.username) {
        Toast({ type: 'fail', duration: 3000, message: '请先填写手机号', className: 'custom-van-toast-width' })
        return
      }

      // 开启机器验证
      this.$refs.verify.show()
    }
  }
}
</script>

<style scoped>
.login_body{
  width: 100vw;
  background-image: url('~@/assets/images/prison_login.jpg');
  background-size:100% 100%;
}
.help_word{
  height:30px;
}
.help_word_text{
  line-height: 20px;
  align-items: center;
  display: flex;
  font-size: 12px;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  color: #ffffff;
}
.header_title{
  width: 100%;
  font-family: SimSun;
  text-align: center;
  color: #ffffff;
  height: 90px;
  font-size: 38px;
  font-weight: bold;
  line-height: 90px;
}
.login_box {
  width: 90vw;
  height: 350px;
  border-radius: 30px;
  background-color: rgb(48, 49, 51, 0.3);
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
::v-deep .van-cell{
  background-color: transparent;
  font-size: 16px;
}
::v-deep .van-field__label{
  color: #fff;
}
.agree_box{
  width: 75vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.isAgree_check{
  width: 30px;
}
.agreeText_box {
  display: inline;
  font-size: 14px;
  margin-left: 5px;
  color: #ffffff;
}
.agreeText_box span {
  color: #409EFF;
}
.document_work {
  width: 90vw;
  height: 500px;
  overflow-x: hidden;
}
.bottom_warning {
  width: 100%;
  height: 17%;
  display: flex;
  flex-direction: column-reverse;
}
::v-deep .docx-wrapper{
  padding: 0!important;
}
::v-deep .docx{
  width: 100%!important;
  display: table!important;
  padding: 2em 1em 0!important;
}
</style>
<style>
.custom-van-toast-width {
  width: 100px;
}
</style>
