<!-- 首页 -->
<template>
  <div class="office_hall">
    <div class="top">
      <van-image class="top_img" :src="require('@/assets/images/0.png')"/>
    </div>
    <div class="login_column">
      <div class="login_column_name" v-if="!username">
        <div>您还未登录，请先登录!</div>
        <div class="login_column_name_btn">
          <router-link :to="{path:'/login'}">
            <van-button style="width: 60px;float: right;margin: 5px 10px" type="info" size="small" round plain>登 录</van-button>
          </router-link>
        </div>
      </div>
      <div class="login_column_name" v-else>
        <div>{{ username + ' 你好！' }}</div>
        <div class="login_column_name_btn">
          <van-button style="width: 60px;float: right;margin: 5px 10px" type="danger" size="small" round plain @click="logOutBtn">注 销</van-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_tab">
        <van-image width="18" height="18" class="tab_icon" :src="require('@/assets/images/star.png')"/>
        <div class="tab_text">
          <span>{{ prisonName }}</span>
        </div>
        <van-image width="18" height="18" class="tab_icon" :src="require('@/assets/images/star.png')"/>
      </div>
      <div class="divide_line"></div>
      <div class="content_body">
        <div @click="clkTag('/remittance')">
          <div class="content_body_flex">
            <van-image class="bac_img" :src="require('@/assets/images/333.png')"/>
            <van-image width="25" height="25" class="inside_icon" :src="require('@/assets/images/4.png')"/>
            <span>亲属汇款</span>
          </div>
        </div>
        <div @click="clkTag('/inmateList')">
          <div class="content_body_flex">
            <van-image class="bac_img" :src="require('@/assets/images/222.png')"/>
            <van-image width="25" height="25" class="inside_icon" :src="require('@/assets/images/2.png')"/>
            <span>服刑人员</span>
          </div>
        </div>
        <div @click="clkTag('/remitRecord')">
          <div class="content_body_flex" >
            <van-image class="bac_img" :src="require('@/assets/images/444.png')"/>
            <van-image width="25" height="25" class="inside_icon" :src="require('@/assets/images/5.png')"/>
            <span>汇款记录</span>
          </div>
        </div>
        <router-link :to="{path:'/personal'}">
          <div class="content_body_flex" >
            <van-image class="bac_img" :src="require('@/assets/images/111.png')"/>
            <van-image width="25" height="25" class="inside_icon" :src="require('@/assets/images/3.png')"/>
            <span>个人中心</span>
          </div>
        </router-link>
      </div>
      <div class="help_word" v-if="username">
        <span class="help_word_text" @click="errorHelpBtn"><van-icon style="margin-right: 5px" name="question-o"/>常见问题</span>
        <span class="help_word_text" @click="helpWordBtn"><van-icon style="margin-right: 5px" name="question-o"/>用户操作指南</span>
      </div>
    </div>
    <!--  操作指南弹框  -->
    <van-popup v-model="show" :close-on-click-overlay="false">
      <div class="document_work" ref="file"></div>
      <div style="padding: 5px"><van-button type="info" plain block round @click="show = false">关闭</van-button></div>
    </van-popup>
    <!--  操作指南弹框  -->
    <van-popup v-model="show1" :close-on-click-overlay="false">
      <div class="document_work" ref="file1"></div>
      <div style="padding: 5px"><van-button type="info" plain block round @click="show1 = false">关闭</van-button></div>
    </van-popup>

    <!--  温馨提示  -->
    <div class="bottom_warning">
      <div style="font-size: 16px; width: 90%;color: #303133; margin: 0 auto 10px;font-weight: bold">
        <span style="color: red">*</span>
        <span style="color: #303133">提示：首次汇款前须在“个人中心”栏准确填写本人真实身份信息，并在“服刑人员”栏准确填写收款人姓名及资金账号。所有信息必须真实无误方可汇款。</span>
      </div>
    </div>
  </div>
</template>

<script>
import { fileReader } from '@/api/file'
// import { getPrisonName } from '@/api'

// 预览插件
const docx = require('docx-preview')
export default {
  name: 'Home',
  data () {
    return {
      username: '',
      // 监区选择器
      showPicker: false,
      show: false,
      show1: false,
      prisonName: '',
      checkPrison: null
    }
  },
  created () {
    this.getUserInfo()

    this.initPrison()
  },
  methods: {
    errorHelpBtn () {
      this.show1 = true
      fileReader(this.$application.errorHelpUrl).then(res => {
        docx.renderAsync(res, this.$refs.file1)
      })
    },
    helpWordBtn () {
      this.show = true
      fileReader(this.$application.userHandleWordUrl).then(res => {
        docx.renderAsync(res, this.$refs.file)
      })
    },
    initPrison () {
      // getPrisonName().then(res => {
      //   this.prisonName = res
      // })
      this.prisonName = localStorage.getItem('prisonName') ? localStorage.getItem('prisonName') : '错误监狱'
    },
    clkTag (type) {
      this.$router.push(type)
    },
    getUserInfo () {
      this.username = this.$store.state.user.name
    },
    async logOutBtn () {
      const toast = this.$toast({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '即将退出登录 3 秒'
      })

      let second = 3
      const timer = setInterval(() => {
        second--
        if (second) {
          toast.message = `即将退出登录 ${second} 秒`
        } else {
          this.$store.dispatch('LogOut').then(() => {
            location.href = '/'
          })
          clearInterval(timer)
          // 手动清除 Toast
          this.$toast.clear()
        }
      }, 1000)
    }
  }
}
</script>
<style scoped>
.top{
  width: 100%;
}
.top_img{
  top: -2px;
}
.login_column {
  width: 100%;
  height: 40px;
}
.login_column_name {
  width: 100%;
  font-size: 15px;
  color: #E6A23C;
  line-height: 40px;
  text-align: center;
  position: absolute;
}
.login_column_name_btn {
  position: absolute;
  right: 10px;
  top: 0;
}
.content {
  width: 330px;
  height: 330px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
.divide_line{
  width: 80%;
  height: 2px;
  background: #a0cfff;
  margin: 0 auto;
}
.content_tab{
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 50px;
}
.content_body{
  height: 220px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.tab_icon{
  margin: 0 10px;
}
.tab_text{
  display: inline-block;
  font-size: 18px;
}
.prison_text{
  width: 150px;
  font-family: NSimSun,serif;
  font-size: 20px;
  font-weight: bold;
}
.prison_text >>> .van-field__control {
  text-align: center;
}
.content_body_flex{
  width: 150px;
  height: 100px;
  text-align: center;
  position: relative;
  font-size: 15px;
  color: #323233;
}
.bac_img{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}
.content_body span{
    display: block;
    width: 100%;
    position: absolute;
    text-align: center;
}
.inside_icon{
    margin-top: 20px;
}
.help_word{
  height: 20px;
}
.help_word_text{
  line-height: 20px;
  align-items: center;
  display: flex;
  font-size: 12px;
  float: right;
  margin-right: 15px;
  color: #409EFF;
}
.document_work {
  width: 90vw;
  height: 500px;
  overflow-x: hidden;
}
.bottom_warning {
  width: 100%;
  height: 17%;
  display: flex;
  flex-direction: column-reverse;
}
::v-deep .docx-wrapper{
  padding: 0!important;
}
::v-deep .docx{
  width: 100%!important;
  display: table!important;
  padding: 2em 1em 0!important;
}
</style>
