import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permisson'
import 'amfe-flexible'
import {
  NavBar, Image as VanImage, Button, List,
  Tag, Col, Row, Cell, CellGroup, Form, Field,
  Uploader, Notify, Checkbox, CheckboxGroup,
  RadioGroup, Radio, Icon, Divider, Toast,
  Tabbar, TabbarItem, SwipeCell, NoticeBar,
  Popup, Picker
} from 'vant'

Vue.prototype.$application = window.application

Vue.use(NavBar).use(VanImage).use(Button).use(List).use(Tag)
  .use(Col).use(Row).use(Cell).use(CellGroup).use(Form).use(Field)
  .use(Uploader).use(Notify).use(Checkbox).use(CheckboxGroup)
  .use(RadioGroup).use(Radio).use(Icon).use(Divider).use(Toast)
  .use(Tabbar).use(TabbarItem).use(SwipeCell).use(NoticeBar)
  .use(Popup).use(Picker)

Vue.config.productionTip = false

// 防止locaStrage在浏览器中被修改
window.addEventListener('storage', function (e) {
  localStorage.setItem(e.key, e.oldValue)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
