<template>
  <div class="approve_html">
    <!-- 拦截认证 -->
    <div class="approve_cover" v-if="isShowCover">
      <div class="approve_icon">
        <van-icon name="warning" size="100" color="#E6A23C"/>
      </div>
      <div class="approve_text">
        您当前还未填写身份信息
      </div>
      <div class="approve_btn">
        <van-button type="primary" block @click="isShowCover = false">立即填写</van-button>
      </div>
    </div>
    <!-- 认证表单 -->
    <div class="approve_form" v-else>
      <div class="form_title fillIn">身份信息填报</div>
      <van-form validate-first colon @submit="submitHandle">
        <van-field
          v-model="form.name"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{required: true , message: '姓名不能为空' }]"
        />
<!--        <van-field-->
<!--          v-model="form.phone"-->
<!--          name="手机号"-->
<!--          label="手机号"-->
<!--          placeholder="请输入手机号码"-->
<!--          :rules="[{pattern: phoneNumV, message: '手机号码有误' }]"-->
<!--        />-->
        <van-field
          v-model="cardNoCompute"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号码"
          :rules="[{pattern: idCardV, message: '身份证号码有误' }]"
        />
<!--        <van-field-->
<!--          v-model="form.bankNo"-->
<!--          name="银行卡号"-->
<!--          label="银行卡号"-->
<!--          placeholder="请输入银行卡号"-->
<!--          :rules="[{required: true , message: '银行卡号不能为空' }]"-->
<!--        />-->
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit" :loading="btnLoading">确认提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>

import { addUserInfo } from '@/api/login'

export default {
  name: 'Attestation',
  data () {
    return {
      // 按钮加载
      btnLoading: false,
      // 信息填写拦截屏幕
      isShowCover: true,
      form: {},
      /* 正则校验 */
      // 身份证
      idCardV: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      // 手机号
      phoneNumV: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
    }
  },
  created () {
    this.restForm()
  },
  computed: {
    cardNoCompute: {
      get: function () {
        return this.form.cardNo
      },
      set: function (val) {
        this.form.cardNo = val.toUpperCase()
      }
    }
  },
  methods: {
    /* 重置表单 */
    restForm () {
      this.form = {
        name: undefined,
        phone: undefined,
        cardNo: undefined,
        bankNo: undefined
      }
    },
    /* 表单提交 */
    submitHandle () {
      this.btnLoading = true
      addUserInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$notify({ type: 'success', message: '提交成功！' })
          this.restForm()
          this.$router.replace({ path: '/home' })
        }
        this.btnLoading = false
      })
    }
  }
}
</script>

<style scoped>
.approve_html {
  width: 100vw;
  height: 100vh;
}
/* 认证 覆盖 */
.approve_cover {
  width: 100%;
  height: 100%;
}
.approve_icon {
  width: 100px;
  height: 100px;
  margin: 120px auto 0;
}
.approve_text {
  width: 100%;
  text-align: center;
  height: 70px;
  line-height: 70px;
  font-size: 30px;
}
.approve_btn {
  width: 150px;
  height: 50px;
  margin: 25px auto 0;
}
/* 认证 表单 */
.approve_form {
  width: 100%;
  height: 100%;
}
.form_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.fillIn {
  background-color: #E4E7ED;
}
</style>
