<!-- 服刑人员绑定页面 -->
<template>
  <div class="bind_inmate">
    <div class="content_center">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-for="(item,index) in list" :key="index">
<!--          <router-link :to="{name:'bindInfo',params: {'inmateId':item.inmateId}}">-->
            <div class="item_info">
              <div class="item_info_top">
                <div class="item_info_top_content">
                  <div class="item_info_top_content_left">
                    <div class="top_left_label">姓名：</div>
                    <div class="top_left_name">
                      <div>{{item.inmateName}}</div>
                      <van-tag class="tag_text" round type="primary" size="large" v-if="item.remittanceAccount ? !!item.remittanceAccount.includes('A') : false ">白名单</van-tag>
                    </div>
                  </div>
                  <div class="item_info_top_content_right">
                    <div class="circl_box">
                      <div :class="item.expire ? 'fw' : 'ft' ">
                        {{item.expire ? '已过期' : '有效'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item_info_bottom">
                <div>{{'资金账号：' + item.fundNumber}}</div>
              </div>
            </div>
<!--          </router-link>-->
        </div>
      </van-list>
    </div>
    <div class="handle_bottom">
      <van-button type="info" round class="bottom_btn" @click="bindingInmate">绑定服刑人员</van-button>
    </div>

    <!--  服刑人员信息验证  -->
    <van-popup v-model="show" position="bottom" @close="closeHandle" style="height: 35%">
      <div class="form_title approve">绑定服刑人员信息</div>
      <van-form validate-first colon label-width="120px" @submit="submitHandle">
        <van-field
          v-model="form.inmateName"
          name="服刑人员姓名"
          label="服刑人员姓名"
          placeholder="请输入姓名"
          @input="checkValue"
          :rules="[{required: true, message: '姓名不能为空！' }]"
        />
        <van-field
          v-model="form.fundNumber"
          name="服刑人员资金号"
          label="服刑人员资金号"
          placeholder="请输入服刑人员资金号"
          :rules="[{required: true, message: '资金账号不能为空！' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">立即绑定</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { getInmatePersonDataBinding, inmateBinding } from '@/api'

export default {
  name: 'InmateList',
  data () {
    return {
      queryParams: {
        page: 0,
        size: 5
      },
      list: [],
      loading: false,
      finished: false,
      error: false,
      // 服刑人员信息验证
      show: false,
      form: {}
    }
  },
  created () {
  },
  methods: {
    onLoad () {
      // 异步更新数据
      this.queryParams.page = this.list.length / this.queryParams.size
      getInmatePersonDataBinding(this.queryParams).then(res => {
        if (res.code === 200) {
          this.list = this.list.concat(res.data.content)
          this.loading = false
          // 数据全部加载完成
          if (this.list.length >= res.data.totalElements) {
            this.finished = true
          }
        } else {
          this.error = false
        }
      })
    },
    checkValue (value) {
      if (value) {
        this.form.inmateName = new Array(value.length).join('*') + value.substr(-1)
      }
    },
    bindingInmate () {
      if (localStorage.getItem('cardNo')) {
        this.show = true
        this.restForm()

        this.form.cardNo = localStorage.getItem('cardNo')
      } else {
        this.$notify({
          type: 'warning',
          message: '账户身份证信息异常，请联系管理员！'
        })
      }
    },
    restForm () {
      this.form = {
        inmateName: '',
        cardNo: '',
        fundNumber: ''
      }
    },
    // 表单提交
    submitHandle () {
      inmateBinding(this.form).then(res => {
        if (res.code === 200) {
          this.$toast({
            type: 'success',
            duration: 3 * 1000,
            message: '绑定成功'
          })
          location.reload()
        } else if (res.code === 500) {
          this.$toast({
            type: 'fail',
            duration: 0,
            overlay: true,
            closeOnClickOverlay: true,
            message: res.message,
            className: 'custom-van-toast-bind-width'
          })
        }
      })
      this.show = false
    },
    // 表单关闭
    closeHandle () {
      this.show = false
      this.restForm()
    }
  }
}
</script>
<style scoped>
.content_center{
  width: 100vw;
  /* height: 89vh; */
  height: 85vh;
  overflow-y: auto;
  /* border-bottom: 1px solid #E4E7ED;a */
}
.content_center::-webkit-scrollbar{
  display: none;
}
.item_info{
    width: 90%;
    height: 110px;
    margin: 15px auto;
    border-radius: 10px;
    background-color: #ecf5ff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.item_info_top{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_info_top_content{
  width: 290px;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.item_info_top_content_left{
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.top_left_label{
  font-size: 13px;
  color: #909399;
  height: 40px;
  width: 40px;
  line-height: 40px;
}
.top_left_name{
  font-size: 28px;
  color: #0f365f;
  font-weight: bold;
  width: 140px;
  height: 80px;
  line-height: 80px;
  position: relative;
}
.tag_text{
  position: absolute;
  top: 5px;
  right: 0;
}
.item_info_top_content_right{
  margin-top: 5px;
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.item_info_bottom{
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item_info_bottom div{
  font-size: 16px;
  color: #0f365f;
  width: 290px;
}
.circl_box{
  text-align: center;
  width: 100px;
  height: 50px;
  padding-top: 5px;
  background-color: #d9ecff;
  border-radius: 50%;
  position: relative;
}
.circl_box div{
  width: 100%;
  line-height: 50px;
  font-size: 16px;
}
.handle_bottom{
  width: 100vw;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.approve {
  color: #FFFFFF;
  background-color: #409EFF;
}
.bottom_btn{
  width: 90%;
  height: 50px;
}
.fe{
  color: #F56C6C;
}
.fb{
  color: #303133;
}
.fg{
  color: #909399;
}
.ft{
  color: #67C23A;
}
.fw{
  color: #E6A23C;
}
</style>
<style>
.custom-van-toast-bind-width {
  width: 160px;
}
</style>
