<template>
  <div class="warning_body">
    <div class="warning_icon"><van-icon name="warning" color="#409EFF" size="100"/></div>
    <div class="warning_text">请使用微信客户端访问</div>
  </div>
</template>

<script>
export default {
  name: 'WinError'
}
</script>

<style scoped>
.warning_body {
  margin-top: 20px;
  text-align: center;
}
.warning_icon {
}
.warning_text {
  font-size: 8px;
}
</style>
