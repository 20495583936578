import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken, getSysAdminLabel, setSysAdminLabel, removeSysAdminLabel } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    sysLabel: getSysAdminLabel(),
    id: '',
    name: '',
    cardNo: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_CARD_NO: (state, cardNo) => {
      state.cardNo = cardNo
    },
    SET_SYS_LABEL: (state, sysLabel) => {
      state.sysLabel = sysLabel
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const phoneCode = userInfo.phoneCode
      const code = userInfo.code
      return new Promise((resolve, reject) => {
        login(username, password, phoneCode, code).then(res => {
          if (res.code === 200) {
            setToken(res.data.password)
            setSysAdminLabel(res.data.label)
            commit('SET_TOKEN', res.data.password)
            commit('SET_SYS_LABEL', res.data.label)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          localStorage.setItem('cardNo', res.data ? res.data.cardNo : '')
          localStorage.setItem('prisonName', res.data ? res.data.prisonName : '')
          commit('SET_NAME', res.data ? res.data.name : '')
          commit('SET_CARD_NO', res.data ? res.data.cardNo : '')
          commit('SET_ID', res.data ? res.data.id : '')
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_NAME', '')
          removeToken()
          removeSysAdminLabel()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }

    // 前端 登出
    // FedLogOut ({ commit }) {
    //   return new Promise(resolve => {
    //     commit('SET_TOKEN', '')
    //     removeToken()
    //     resolve()
    //   })
    // }
  }
}

export default user
