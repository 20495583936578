import request from '@/utils/request.js'

const commonProxy = '/prison-Family-app'

// 获取服刑人员列表(管理员权限)
export function getInmatePersonData (query) {
  return request({
    url: commonProxy + '/applet/peopleBinding/getRemittablePeopleList',
    method: 'post',
    data: query
  })
}

// 获取服刑人员列表(登陆人查询)
export function getInmatePersonDataBinding (query) {
  return request({
    url: commonProxy + '/applet/peopleBinding/relativesPeopleBinding',
    method: 'post',
    data: query
  })
}

// 获取服刑人员账户余额
export function getAccountBalance (query) {
  return request({
    url: commonProxy + '/fund/inmateAccount/queryAccount',
    method: 'post',
    data: query
  })
}

// 汇款接口
export function startRecharge (query) {
  return request({
    url: commonProxy + '/applet/remittance/startRecharge',
    method: 'post',
    data: query
  })
}

// 查询服刑人员汇款限制信息
export function getInmateLimitInfo (query) {
  return request({
    url: commonProxy + '/applet/remittance/limit',
    method: 'post',
    data: query
  })
}

// 获取汇款记录信息
export function getOrderList (query) {
  return request({
    url: commonProxy + '/order/remittOrder/orderList',
    method: 'post',
    data: query
  })
}

// 待支付订单再次支付
export function repayment (query) {
  return request({
    url: commonProxy + '/order/remittOrder/repayments',
    method: 'post',
    data: query
  })
}

// 亲属填报服刑人员信息
export function inmateBinding (data) {
  return request({
    url: commonProxy + '/applet/peopleBinding/check/',
    method: 'post',
    data: data
  })
}
