// 基于axios封装网络请求
import theAxios from 'axios'
import { getToken, getSysAdminLabel, removeToken, removeSysAdminLabel } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { Toast } from 'vant'
import store from '@/store'
import '../styles/common.css'

// 设置请求头编码格式
theAxios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建实例
const service = theAxios.create({
  baseURL: '/',
  timeout: 1000 * 60
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 过滤登录接口
  const withoutTokenUrl = config.url === '/relatives/login'
  if (getToken() && !isToken && !withoutTokenUrl) {
    config.headers['Authorization-Relatives'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['UNIT-CODE'] = getSysAdminLabel()
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      const part = encodeURIComponent(propName) + '='
      if (value !== null && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + '&'
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.message || errorCode.default
  if (code === 403) {
    // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
    //     confirmButtonText: '重新登录',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }
    // ).then(() => {
    //   store.dispatch('LogOut').then(() => {
    //     location.href = '/index';
    //   })
    // }).catch(() => {});
    const toast = Toast({
      type: 'fail',
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: '登录信息已过期\n即将退出系统 3 秒',
      className: 'custom-van-toast-request-width'
    })

    let second = 3
    const timer = setInterval(() => {
      second--
      if (second) {
        toast.message = `登录信息已过期\n即将退出系统 ${second} 秒`
      } else {
        // 退出登录
        store.dispatch('LogOut').then(() => {
          location.href = '/'
        })

        clearInterval(timer)
        // 手动清除 Toast
        Toast.clear()
      }
    }, 1000)
  } else if (code === 500) {
    return res.data
  } else if (code === 401 || code === 601) {
    // 判断是否是登录页面
    // eslint-disable-next-line no-unused-vars
    let isLoginPage = false
    if (window.location.href.includes('login')) isLoginPage = true
    // 挤下线、系统关闭
    const toast = Toast({
      type: 'fail',
      duration: isLoginPage ? 1800 : 0, // 持续展示 toast
      forbidClick: true,
      message: isLoginPage ? res.data.message : res.data.message + '\n 即将退出系统 3 秒',
      className: 'custom-van-toast-request-width'
    })

    if (!isLoginPage) {
      let second = 3
      const timer = setInterval(() => {
        second--
        if (second) {
          toast.message = res.data.message + `\n 即将退出系统 ${second} 秒`
        } else {
          // 退出登录
          store.dispatch('LogOut').then(() => {
            location.href = '/'
          })

          clearInterval(timer)
          // 手动清除 Toast
          Toast.clear()
        }
      }, 1000)
    } else {
      // 在登录页面手动清除token
      removeToken()
      removeSysAdminLabel()
    }
  } else if (code !== 200) {
    Toast({
      type: 'fail',
      duration: 3 * 1000,
      message: msg
    })
    return res.data
  } else {
    return res.data
  }
},
error => {
  console.log('err' + error)
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  // Message({
  //   message: message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  Toast({
    type: 'fail',
    duration: 5 * 1000,
    message: message
  })
  return Promise.reject(error)
})

export default service
