<!-- 绑定信息详情 -->
<template>
  <div class="bind_info">
    <div class="content_body">
      <div class="relatives_info">
        <div class="title">
          <div class="title_img_box">
            <van-image class="title_icon" :src="require('@/assets/images/11.png')"></van-image>
          </div>
          <div class="title_content">亲属信息</div>
        </div>
        <div class="content">
          <van-cell-group inset>
            <van-cell title="姓名" value="张三" />
            <van-cell title="手机号码" value="15792389823" />
            <van-cell title="身份证号" value="510291587438776642" value-class="cell-delete-overflow"/>
            <van-cell title="亲属身份">
              <template>
                <van-tag type="success" size="medium">白名单</van-tag>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
      <div class="inmate_info">
        <div class="title">
          <div class="title_img_box">
            <van-image class="title_icon" :src="require('@/assets/images/11.png')"></van-image>
          </div>
          <div class="title_content">服刑人员信息</div>
        </div>
        <div class="content">
          <van-cell-group inset>
            <van-cell title="人员编号" value="22222222222222222222" value-class="cell-delete-overflow"/>
            <van-cell title="手机号码" value="15792389823" />
            <van-cell title="身份证号" value="51029158743877664" />
          </van-cell-group>
        </div>
      </div>
      <div class="approve_info">
        <div class="title">
          <div class="title_img_box">
            <van-image class="title_icon" :src="require('@/assets/images/11.png')"></van-image>
          </div>
          <div class="title_content">审核状态</div>
        </div>
        <div class="content">
          <van-cell-group inset>
            <van-cell label="图片不清晰，请按照示例重新上传！">
              <template #title>
                <van-tag type="danger" size="large">审核失败</van-tag>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
      <div class="valid_info">
        <div class="title">
          <div class="title_img_box">
            <van-image class="title_icon" :src="require('@/assets/images/11.png')"></van-image>
          </div>
          <div class="title_content">亲属信息有效状态</div>
        </div>
        <div class="content">
          <van-cell-group inset>
            <van-cell label="审核有效期到期，请重新绑定人员信息！">
              <template #title>
                <van-tag type="danger" size="large">已过期</van-tag>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BindInfo',
  data () {
    return {
      // 服刑人员ID
      inmateId: ''
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    // 初始化页面参数
    initPageData () {
      this.inmateId = this.$route.params.inmateId
    }
  }
}
</script>
<style lang="less" scoped>
.bind_info{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content_body{
  width: 90%;
  height: 90%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  background-color: #F2F3F5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.relatives_info{
  width: 100%;
}
.title{
  width: 90%;
  height: 30px;
  margin: 0 auto;
  display: flex;
}
.title_img_box{
  width: 35px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.title_icon{
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.title_content{
  width: 80%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
.inmate_info{
  width: 100%;
}
.approve_info{
  width: 100%;
}
.valid_info{
  width: 100%;
}
.cell-delete-overflow{
  overflow: visible;
}
</style>
