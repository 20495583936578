import router from '@/router'
import { getToken, removeToken, removeSysAdminLabel } from '@/utils/auth'
import store from '@/store'
import { Toast } from 'vant'

router.beforeEach((to, form, next) => {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') !== -1
  if (!isWeixin && to.path !== '/winError') {
    router.replace('/winError')
  }

  // 校验同一亲属给多个地方人员汇款需要重新登录的问题
  if (to.query.prisonCode !== undefined) {
    if (localStorage.getItem('prisonCode') !== to.query.prisonCode) {
      // 赋予新的单位编码
      localStorage.setItem('prisonCode', to.query.prisonCode)
      // 重新登录
      removeToken()
      removeSysAdminLabel()
      next({ path: '/login' })
    }
  }

  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.requireAuth) { // 判断跳转的路由是否需要登录，如果前端没有登录信息则直接拦截，如果有则判断后端是否正常登录（防止构造参数绕过）
    if (getToken()) { // vuex.state判断token是否存在 即是否有用户登录
      next()
    } else {
      Toast({
        type: 'fail',
        duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: '请登录'
      })

      const timer = setInterval(() => {
        store.dispatch('LogOut').then(() => {
          router.push('/login')
        })
        clearInterval(timer)
        // 手动清除 Toast
        Toast.clear()
      }, 1500)
    }
  } else {
    // 登录缓存检测
    if (to.path === '/login' && getToken()) {
      next({ path: '/home' })
    } else if (to.path === '/home' && getToken()) { // 判断是否是首页
      store.dispatch('GetInfo').then(r => {
        if (r.code === 500 && r.message === '该亲属不存在！') {
          router.push('/attestation')
        } else {
          next() // 已登录进行跳转
        }
      })
    } else {
      next()
    }
  }
})
