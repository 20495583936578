import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const LableKey = 'Admin-label'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getSysAdminLabel () {
  return Cookies.get(LableKey)
}

export function setSysAdminLabel (label) {
  return Cookies.set(LableKey, label)
}

export function removeSysAdminLabel () {
  return Cookies.remove(LableKey)
}
