import request from '@/utils/request'

const commonProxy = '/prison-Family-app'

export function login (username, password, phoneCode, code) {
  const data = {
    username,
    password,
    phoneCode,
    code
  }
  return request({
    url: commonProxy + '/relatives/phoneCodeLogin',
    method: 'post',
    data: data
  })
}

// 获取验证码
export function sendPhoneCode (data) {
  return request({
    url: commonProxy + '/relatives/sendPhoneCode',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo () {
  return request({
    url: commonProxy + '/applet/relatives/signIn',
    method: 'post'
  })
}

// 用户信息填报
export function addUserInfo (data) {
  return request({
    url: commonProxy + '/applet/relatives/add',
    method: 'post',
    data: data
  })
}

// 用户信息认证
export function authentication (id) {
  return request({
    url: commonProxy + '/applet/relatives/authentication/' + id,
    method: 'post'
  })
}

// 用户信息修改
export function updateUserInfo (params) {
  return request({
    url: commonProxy + '/applet/relatives/update',
    method: 'post',
    data: params
  })
}

// 用户修改密码
export function modifyUserPassword (params) {
  return request({
    url: commonProxy + '/relatives/user/modify',
    method: 'post',
    data: params
  })
}

// 退出方法
export function logout () {
  return request({
    url: commonProxy + '/relatives/logout',
    method: 'post'
  })
}
