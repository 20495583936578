import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Remittance from '@/views/Remittance'
import InmateList from '@/views/InmateList'
import BindRelatives from '@/views/BindRelatives'
import RemitRecord from '@/views/RemitRecord'
import Personal from '@/views/Personal'
import BindInfo from '@/views/BindInfo'
import BindInmate from '@/views/BindInmate'
import Login from '@/views/Login'
import Attestation from '@/views/Personal/attestation'
import WinError from '@/views/WinError'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  // 登录页面
  {
    path: '/login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  // 首页
  {
    path: '/home',
    component: Home,
    meta: {
      title: '首页'
      // requireAuth: true // 此时表示进入这个路由是需要登录的
    }
  },
  // 亲属汇款
  {
    path: '/remittance',
    component: Remittance,
    meta: {
      requireAuth: true, // 此时表示进入这个路由是需要登录的
      title: '亲属汇款'
    }
  },
  // 服刑人员列表
  {
    path: '/inmateList',
    component: InmateList,
    meta: {
      requireAuth: true, // 此时表示进入这个路由是需要登录的
      title: '服刑人员'
    }
  },
  // 绑定亲属信息
  {
    path: '/bindRelatives',
    component: BindRelatives
  },
  //
  {
    path: '/bindInmate',
    name: 'bindInmate',
    component: BindInmate
  },
  // 绑定详情信息
  {
    path: '/bindInfo',
    name: 'bindInfo',
    component: BindInfo
  },
  // 汇款记录
  {
    path: '/remitRecord',
    component: RemitRecord,
    meta: {
      requireAuth: true, // 此时表示进入这个路由是需要登录的
      title: '汇款记录'
    }
  },
  // 个人中心
  {
    path: '/personal',
    component: Personal,
    meta: {
      requireAuth: true, // 此时表示进入这个路由是需要登录的
      title: '个人中心'
    }
  },
  // 亲属认证
  {
    path: '/attestation',
    component: Attestation,
    meta: {
      requireAuth: true,
      title: '亲属认证'
    }
  },
  // PC端错误访问
  {
    path: '/winError',
    component: WinError,
    meta: {
      title: '错误访问'
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
