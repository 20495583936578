<!-- 个人中心 -->
<template>
  <div class="personal_body">
    <div class="content_body">
      <div class="relatives_info">
        <div class="title">
          <div class="title_img_box">
            <van-image class="title_icon" :src="require('@/assets/images/11.png')"></van-image>
          </div>
          <div class="title_content">亲属信息</div>
        </div>
        <div class="content">
          <van-cell-group inset>
            <van-cell title="姓名" :value="name" />
<!--            <van-swipe-cell>-->
<!--              <van-cell title="手机号码" :value="phone" />-->
<!--              <template #right>-->
<!--                <van-button square block style="height: 100%" type="info" text="修改" @click="phonePopupOpen"/>-->
<!--              </template>-->
<!--            </van-swipe-cell>-->
            <van-cell title="身份证号" :value="cardNo" value-class="cell-delete-overflow">
            </van-cell>
          </van-cell-group>
        </div>
        <div style="display: flex;justify-content: center;margin-top: 10px">
          <van-button type="primary" size="small" round @click="idCardPopupOpen">点击修改个人信息</van-button>
        </div>
      </div>
<!--      <van-notice-bar scrollable text="认证失败！请仔细核对身份证号、银行卡号是否为本人的信息！" v-if="authentication === 2"/>-->
<!--      <div class="approve_info">-->
<!--        <div class="title">-->
<!--          <div class="title_img_box">-->
<!--            <van-image class="title_icon" :src="require('@/assets/images/11.png')"></van-image>-->
<!--          </div>-->
<!--          <div class="title_content">实名认证</div>-->
<!--        </div>-->
<!--        <div class="content">-->
<!--          <van-cell-group inset>-->
<!--            <van-cell :label="'银行卡号：' + bankNo">-->
<!--              <template #title>-->
<!--                <template v-if="authentication === 0" >-->
<!--                  <van-tag type="warning" size="large" style="height: 20px">未认证</van-tag>-->
<!--                  &lt;!&ndash; 等银行的认证接口 &ndash;&gt;-->
<!--                  &lt;!&ndash;<van-button round type="info" :loading="approveLoading" size="small" style="float: right" @click="approveHandle">立即认证</van-button>&ndash;&gt;-->
<!--                </template>-->
<!--                <template v-if="authentication === 2">-->
<!--                  <van-tag type="warning" size="large" style="height: 20px">认证失败</van-tag>-->
<!--                  &lt;!&ndash; 等银行的认证接口 &ndash;&gt;-->
<!--                  &lt;!&ndash;<van-button round type="info" size="small" style="float: right" @click="backfillPersonInfo">重新认证</van-button>&ndash;&gt;-->
<!--                </template>-->
<!--                <van-tag type="success" size="large" v-if="authentication === 1">已认证</van-tag>-->
<!--              </template>-->
<!--            </van-cell>-->
<!--          </van-cell-group>-->
<!--        </div>-->
<!--      </div>-->
      <div class="valid_info">
        <div class="title">
          <div class="title_img_box">
            <van-image class="title_icon" :src="require('@/assets/images/11.png')"></van-image>
          </div>
          <div class="title_content">账号信息</div>
        </div>
        <div class="content">
          <van-cell-group inset>
            <van-cell title="账号" :value="userName" />
<!--            <van-cell title="密码" center>-->
<!--              <template #right-icon>-->
<!--                <van-button round type="info" size="small" @click="passwordPopupOpen">修改密码</van-button>-->
<!--              </template>-->
<!--            </van-cell>-->
          </van-cell-group>
        </div>
      </div>
    </div>
    <!--  重新认证表单  -->
    <van-popup v-model="reApproveShow" position="bottom" @close="getList">
      <div class="form_title approve">重新认证信息填报</div>
      <van-form validate-first colon @submit="submitHandle">
        <van-field
          v-model="form.name"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{required: true , message: '姓名不能为空' }]"
        />
        <van-field
          v-model="form.phone"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号码"
          :rules="[{pattern: phoneNumV, message: '手机号码有误' }]"
        />
        <van-field
          v-model="form.cardNo"
          name="身份证号"
          label="身份证号"
          placeholder="请输入身份证号码"
          :rules="[{pattern: idCardV, message: '身份证号码有误' }]"
        />
        <van-field
          v-model="form.bankNo"
          name="银行卡号"
          label="银行卡号"
          placeholder="请输入银行卡号"
          :rules="[{required: true , message: '银行卡号不能为空' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">立即认证</van-button>
        </div>
      </van-form>
    </van-popup>
    <!--  手机号修改  -->
    <van-popup v-model="idCardPopup" position="bottom" @close="getList">
      <div class="form_title approve">重新绑定个人信息</div>
      <van-form validate-first colon @submit="updatePhoneNum">
        <van-field
          v-model="newName"
          name="姓名"
          label="姓名"
          placeholder="请输入新的姓名"
          :rules="[{required: true , message: '姓名不能为空' }]"
        />
        <van-field
          v-model="cardNoCompute"
          name="身份证号"
          label="身份证号"
          placeholder="请输入新的身份证号码"
          :rules="[{pattern: idCardV, message: '身份证号码有误' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">确认修改</van-button>
        </div>
      </van-form>
    </van-popup>
    <!--  密码修改  -->
    <van-popup v-model="passwordPopup" position="bottom" @close="getList">
      <van-form validate-first colon @submit="updatePassword">
        <van-field
          v-model="oldPassword"
          name="旧密码"
          label="旧密码"
          type="password"
          placeholder="请输入旧的密码"
        />
        <van-field
          v-model="newPassword"
          name="新密码"
          label="新密码"
          type="password"
          placeholder="请输入新的密码"
        />
        <van-field
          v-model="confirmPassword"
          name="确认密码"
          label="确认密码"
          type="password"
          placeholder="请输入新的密码"
          :rules="[{ validator: checkPassword, message: '两次密码输入不一致！' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">确认修改</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { authentication, getInfo, modifyUserPassword, updateUserInfo } from '@/api/login'

export default {
  data () {
    return {
      // 展示参数
      id: undefined,
      name: undefined,
      cardNo: undefined,
      phone: undefined,
      userName: undefined,
      bankNo: undefined,
      authentication: undefined,
      // 重新认证
      reApproveShow: false,
      // 修改个人信息
      idCardPopup: false,
      newIdCardNum: undefined,
      newName: undefined,
      // 修改密码
      passwordPopup: undefined,
      oldPassword: undefined,
      newPassword: undefined,
      confirmPassword: undefined,
      form: {},
      // 身份证
      idCardV: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      // 手机号
      phoneNumV: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
      // 立即认证加载按钮
      approveLoading: false
    }
  },
  created () {
    this.getList()
    this.form.id = this.$store.state.user.id
  },
  computed: {
    cardNoCompute: {
      get: function () {
        return this.newIdCardNum
      },
      set: function (val) {
        this.newIdCardNum = val.toUpperCase()
      }
    }
  },
  methods: {
    /* 回填亲属信息 */
    backfillPersonInfo () {
      // 开启认证弹框
      this.reApproveShow = true
      // 获取人员信息
      if (this.authentication === 2) {
        getInfo().then(res => {
          if (res.code === 200) {
            this.form = res.data
          }
        })
      }
    },
    /* 身份证号修改表单 */
    idCardPopupOpen () {
      this.idCardPopup = true
      this.newIdCardNum = this.cardNo
      this.newName = this.name
    },
    /* 手机号修改 */
    updatePhoneNum () {
      updateUserInfo({ id: this.id, name: this.newName, cardNo: this.newIdCardNum, phone: this.phone, bankNo: this.bankNo }).then(res => {
        if (res.code === 200) {
          this.$notify({ type: 'success', message: '修改成功！' })
          this.idCardPopup = false
          this.newIdCardNum = undefined
          this.newName = undefined
        }
      })
    },
    passwordPopupOpen () {
      this.passwordPopup = true
      this.oldPassword = undefined
      this.newPassword = undefined
      this.confirmPassword = undefined
    },
    /* 密码修改 */
    updatePassword () {
      modifyUserPassword({ oldPassword: this.oldPassword, newPassword: this.newPassword }).then(res => {
        if (res.code === 200) {
          const toast = this.$toast({
            type: 'success',
            forbidClick: true,
            duration: 0, // 持续展示 toast
            message: '修改成功\n即将重新登录 3 秒'
          })
          let second = 3
          const timer = setInterval(() => {
            second--
            if (second) {
              toast.message = `修改成功\n即将重新登录 ${second} 秒`
            } else {
              this.$store.dispatch('LogOut').then(() => {
                this.$router.push('/login')
              })
              clearInterval(timer)
              // 手动清除 Toast
              this.$toast.clear()
            }
          }, 1000)
        } else {
          this.$toast.fail(res.message)
        }
      })
    },
    /* 确认密码 */
    checkPassword () {
      return this.newPassword === this.confirmPassword
    },
    submitHandle () {
      updateUserInfo(this.form).then(res => {
        if (res.code === 200) {
          authentication(this.id).then(res => {
            if (res.code === 200) {
              this.$notify({ type: 'success', message: '认证成功！' })
              this.reApproveShow = false
              this.getList()
            } else {
              this.$notify({ type: 'warning', message: '认证失败，请核对身份信息！' })
            }
          })
        } else {
          this.$notify({ type: 'warning', message: '身份信息修改错误！' })
        }
      })
    },
    getList () {
      getInfo().then(res => {
        if (res.code === 200) {
          this.id = res.data.id
          this.name = res.data.name
          this.cardNo = res.data.cardNo
          this.phone = res.data.phone
          this.userName = res.data.userName
          this.bankNo = res.data.bankNo
          this.authentication = res.data.authentication
        }
      })
    },
    approveHandle () {
      this.approveLoading = true
      authentication(this.id).then(res => {
        if (res.code === 200) {
          this.$notify({ type: 'success', message: '认证成功！' })
          this.approveLoading = false
          this.getList()
        } else {
          this.$notify({ type: 'warning', message: '认证失败！' })
          this.approveLoading = false
          this.getList()
        }
      })
    }
  }
}

</script>
<style scoped>
.personal_body{
  width: 100vw;
  height: 100vh;
}
.content_body{
  width: 90%;
  height: 280px;
  border-radius: 10px;
  margin: 15px auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  background-color: #F2F3F5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.relatives_info{
  width: 100%;
}
.title{
  width: 90%;
  height: 30px;
  margin: 0 auto;
  display: flex;
}
.title_img_box{
  width: 35px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.title_icon{
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.title_content{
  width: 80%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
.cell-delete-overflow{
  overflow: visible;
}
.form_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.approve {
  color: #FFFFFF;
  background-color: #409EFF;
}
</style>
