import request from '@/utils/request.js'

const commonProxy = '/file'

// 获取验证码
export function fileReader (url) {
  return request({
    url: commonProxy + '/fileReader',
    method: 'post',
    responseType: 'blob',
    data: { fileUrl: url }
  })
}
